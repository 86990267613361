/*
  公共工具函数库
*/

/**
 * @description 从URL中判断文件类型
 * @property {String} option = 文件地址
 * @property {Result} type = 1：视频，2：音频
 */
const httpsVideoReg = new RegExp(
  /^(http|https)?:\/\/(.+\/)+.+(\.(avi|flv|mpg|mpeg|mov|mkv|rmvb|mp4))$/i
);
const httpsMusicReg = new RegExp(
  /^(http|https)?:\/\/(.+\/)+.+(\.(mp3|ogg|wav|wma))$/i
);
const httpsSwfReg = new RegExp(/^(http|https)?:\/\/(.+\/)+.+(\.(swf))$/i);
export function eVideoGetFileType(url) {
  if (httpsVideoReg.test(url)) {
    // 视频
    return 1;
  } else if (httpsMusicReg.test(url)) {
    // 音频
    return 2;
  } else if (httpsSwfReg.test(url)) {
    // SWF
    return 3;
  } else {
    return 0;
  }
}

// 游戏环境配置
export const GameEnvConfig = {
  deviceName: "PC",
  deviceVersion: "0.0.0",
  softName: require("../../package.json").name,
  softVersion: require("../../package.json").version,
};

// 设置游戏子应用读取的环境配置
export const setWindowEnv = () => {
  Object.defineProperty(window, "Env", {
    value: GameEnvConfig,
    configurable: true,
  });
};

// 游戏应用消息对象
export class GameMessageData {
  data = null;
  errorCode = 0;
  errorMessage = "success";
  constructor(data = null, errorCode = 0, errorMessage = "success") {
    this.data = data;
    this.errorCode = errorCode;
    this.errorMessage = errorMessage;
  }
}

// 发送成功的消息
export const GameMessageSuccess = (data = null) => {
  return new GameMessageData(data);
};
// 发送失败的消息
export const GameMessageError = (errorMessage = "error") => {
  return new GameMessageData(null, 1, errorMessage);
};
